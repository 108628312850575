import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import GoogleMap from '../../components/GoogleMap'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact</h1>
              <div class="columns-2">
                <div className="column is-2">
                  <p>
                    <strong>Address</strong>
                    <br/>
                    Corky's Sod Farm
                    <br/>
                    972 Adahi Lane
                    <br/>
                    Brierfield, Al 35035
                  </p>
                  <p>
                  <strong>Hours</strong>
                    <br/>
                    M-F 8-5
                  </p>
                </div>
                <div className="column is-2">
                  <p>
                  <strong>Phone</strong>
                    <br/>
                    Wade Bice: 205-438-2465
                    <br/>
                    Richard Burton: 205-365-0943
                  </p>
                </div>
                <div class="column is-8">
                  <GoogleMap></GoogleMap>
                </div>

              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
